// Generated by Framer (61bac00)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {Xqx1FrL6M: {hover: true}};

const cycleOrder = ["Xqx1FrL6M"];

const serializationHash = "framer-3l5xU"

const variantClassNames = {Xqx1FrL6M: "framer-v-1j9975u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, link, newTab, width, ...props}) => { return {...props, PGI7Y7MBo: link ?? props.PGI7Y7MBo, VFtEjUOeV: newTab ?? props.VFtEjUOeV ?? true, ZITaI3QZh: image ?? props.ZITaI3QZh ?? {src: "https://framerusercontent.com/images/Os4pbd3o43TGGOfZfWDTkFIjCs.png"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ZITaI3QZh, PGI7Y7MBo, VFtEjUOeV, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Xqx1FrL6M", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><Link href={PGI7Y7MBo} openInNewTab={VFtEjUOeV}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1j9975u", className, classNames)} framer-3wqvsr`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Xqx1FrL6M"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-1b16e4e2-8780-4afe-aa5a-21604c3aa764, rgb(0, 0, 0))", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}} variants={{"Xqx1FrL6M-hover": {backgroundColor: "var(--token-fa22f986-965c-4857-a55b-4df137003671, rgb(36, 36, 36))"}}} {...addPropertyOverrides({"Xqx1FrL6M-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 66, intrinsicWidth: 66, pixelHeight: 66, pixelWidth: 66, sizes: "33px", ...toResponsiveImage(ZITaI3QZh)}} className={"framer-jlmfdw"} layoutDependency={layoutDependency} layoutId={"FbdIiA_YZ"}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-3l5xU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3l5xU .framer-3wqvsr { display: block; }", ".framer-3l5xU.framer-1j9975u { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 151px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 100px; }", ".framer-3l5xU .framer-jlmfdw { flex: none; height: 33px; overflow: visible; position: relative; width: 33px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-3l5xU.framer-1j9975u { gap: 0px; } .framer-3l5xU.framer-1j9975u > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-3l5xU.framer-1j9975u > :first-child { margin-left: 0px; } .framer-3l5xU.framer-1j9975u > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 151
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ZhEymeTiX":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ZITaI3QZh":"image","PGI7Y7MBo":"link","VFtEjUOeV":"newTab"}
 * @framerImmutableVariables true
 */
const FramerSuvfnexrW: React.ComponentType<Props> = withCSS(Component, css, "framer-3l5xU") as typeof Component;
export default FramerSuvfnexrW;

FramerSuvfnexrW.displayName = "UI — Button | Social Link";

FramerSuvfnexrW.defaultProps = {height: 151, width: 100};

addPropertyControls(FramerSuvfnexrW, {ZITaI3QZh: {__defaultAssetReference: "data:framer/asset-reference,Os4pbd3o43TGGOfZfWDTkFIjCs.png?originalFilename=social-github.png&preferredSize=full", title: "Image", type: ControlType.ResponsiveImage}, PGI7Y7MBo: {title: "Link", type: ControlType.Link}, VFtEjUOeV: {defaultValue: true, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerSuvfnexrW, [])